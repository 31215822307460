<template>
   <div class="results test1">
      <div class="title ">
        <div class="titles ">
          <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
          最高授信额度测算
        </div>
        <div style="text-align: right;font-size:16px;color:#333">
          信用等级调整系数
          <el-autocomplete style="width: 150px;font-size:16px;color:#333" size="small" popper-class="my-autocomplete"
            v-model="state" @blur="onblur(even)" :fetch-suggestions="querySearch" min="0.5" max="1.5" placeholder="请输入调整系数" @select="handleSelect">
            <template slot-scope="{ item }">
              <div class="name">{{ item.value }}</div>
            </template>
          </el-autocomplete>
        </div>
      </div>
      <div style="padding: 10px; margin-top: 100px;" >
        <du-pont-analysiss :duBangHigh="maxHigh"></du-pont-analysiss>
      </div>
    </div>
</template>

<script>
import { calculateHigh } from "@/api/common"
import * as sesstion from "@/store/localStorage";
export default {
  components: {
    DuPontAnalysiss: require("./CapitalCalculation/DuPontAnalysis.vue").default,
  },
  data(){
    return {
      restaurants: [],
      state: '0.5',
      maxHigh: {},
    }
  },
  mounted(){
    this.calculateHigh()
    this.restaurants = this.loadAll();
  },
  methods:{
    calculateHigh() {
      const company = sesstion.getLocalData("companyData");
      // console.log(company);
      let param = {
        year: company.year,
        company_name: company.name,
        company_code: company.company_code,
        report_time: company.report_time,
        report_type: company.report_type,
        industry_code: company.industry_code,
        coefficient: this.state
      }
      calculateHigh(param).then((res) => {
        this.maxHigh = res.data
      })
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    loadAll() {
      return [
        { "value": "0.5" },
        { "value": "0.6" },
        { "value": "0.7" },
        { "value": "0.8" },
        { "value": "0.9" },
        { "value": "1.0" },
        { "value": "1.2" },
        { "value": "1.3" },
        { "value": "1.4" },
        { "value": "1.5" },
      ];
    },
    handleSelect(item) {
      this.calculateHigh()
    },
    onblur(){
      this.calculateHigh()
    },
  },
}
</script>

<style scoped lang="less">
.results{
  padding: 20px;
  margin-top: 15px;
  background: #fff;
  min-height: 650px;
  .title {
    // padding: 20px;
    font-size: 20px;

    background: #fff;
    display: flex;
    justify-content: space-between;

    .titles {
      font-weight: 550;
      padding: 0px !important;
    }
  }
}

</style>