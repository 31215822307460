<template>
  <div class="DuPontAnalysis">
    <div :style="`width: ${width}px`">
      <item
        :name="'最高授信额度'"
        :value="duBangHigh.high_value"
        down="1"
      />
    </div>
    <div :style="`width: ${width * 6 + rule * 3}px; justify-content: space-between;`">
      <item :name="'行业负债权益比'" :value="duBangHigh.hyfzqyb_value" up="1" left="[(" />
      <item :name="'客户负债权益比'" :value="duBangHigh.khfzqyb_value" up="1" left="-" />
      <item :name="'有效净资产'" :value="duBangHigh.yxjzc_value" up="1" left=")*" />
      <item :name="'基期负债'" :value="duBangHigh.jqfz_value" up="1" left="+" />
      <item :name="'行业带息负债比率'" :value="duBangHigh.hydxfzbl_value" up="1" left="]*" />
      <item :name="'信用等级调整系数'" :value="duBangHigh.coefficient" up="1" left="*" />
      <div :style="`width: ${width * 5 + rule * 3}px;`" class="line"></div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    item: require("./item").default,
  },
  props: {
    duBangHigh: Object,
  },
  data() {
    return {
      width: 150,
      rule: 80,
    };
  },
};
</script>
<style lang="less" scoped>
.DuPontAnalysis {
  min-height: 400px;
  margin-top: 20px;

  > div {
    margin: 0 auto;
    display: flex;
    margin-bottom: 40px;
    position: relative;
    .line {
      position: absolute;
      left: 50%;
      top: -20px;
      height: 1px;
      width: 100%;
      background: #2b92fa;
      transform: translateX(-50%);
      
    }
  }
}
</style>
