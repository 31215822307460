import service from "@/common/request.js";
/**
 * 获取财务风险分析饼图数据
 * {
  "report_year": "2020",
  "report_type": "00",
  "report_time": "01",
  "industry_range": "0",
  "industry_code": "tj0701",
  "industry_name": "房地产业/房地产开发业",
  "company_code": "63e992cc-abf1-11e8-86fc-1c83410ad7e3",
  "name": "万科A",
  "stock_code": "000002",
  "company_name": "万科企业股份有限公司"
}
 */
export const pie_data = (prame) => {
  return service.post("/risk/index-calc/safety/", prame);
};

export const pie_data_fin = (prame) => {
  return service.post("/risk/capability-analysis/safety/", prame);
};

// 获取结构分析数据
export const financialEcharts = (prame) => {
  return service.post("/risk/fs-structure/", prame);
};

// 获取科目异动分析数据
export const subject_transaction = (prame) => {
  return service.post("/risk/subject_transaction/", prame);
};
/**
 * 评级
 * {
  "year": "2020",

  "report_time": "00",
  company_name: "",
  company_code: "",
  safety_value: "",
  truth_value: "",
}
 */
export const rating = (prame) => service.post("/financing-calc/rating/healthy/", prame);

// 此演示版本不用
// export const calculateHigh = (prame) => service.post("/financing-calc/high/", prame);

/**
 *
 * 获取省份选择器数据
 */
export const indicators = () => service.get("/data/ec/indicators/");

export const regions = () => service.get("/data/ec/regions/");
