<template>
  <div class="box">
    <div class="name">{{ name }}</div>
    <div class="value">
      <span v-text="value"></span>
      <!-- <el-tooltip v-if="desc" placement="top">
        <div slot="content">
          <span>{{ desc }}</span>
        </div>
        <i class="el-tooltip el-icon-question item"></i>
      </el-tooltip> -->
    </div>
    <div v-if="up" class="up"></div>
    <div v-if="down" class="down"></div>
    <div v-if="left" class="left">{{ left }}</div>
    <div v-if="right" class="right">{{ right }}</div>
  </div>
</template>
<script>
export default {
  props: {
    up: String,
    down: String,
    name: {
      type: String,
      default: "-",
    },
    value: {
      type: String,
      default: "-",
    },
    left: String,
    right: String,
    desc: String,
  },
};
</script>
<style lang="less" scoped>
.box {
  width: 150px;
  position: relative;
  font-size: 14px;
  border-radius: 5px;
  background: #fff;
  .name {
    border: 1px solid #2b92fa;
    background: #e6f1fc;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #6bb3fa;
    text-align: center;
  }
  .value {
    text-align: center;
    color: #fd9d93;
    border: 1px solid rgb(226, 226, 226);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .up,
  .down {
    height: 20px;
    background: #2991fa;
    width: 1px;
    position: absolute;
    left: 50%;
    margin-left: -1px;
  }
  .up {
    top: 0;
    transform: translateY(-100%);
  }
  .down {
    bottom: 0;
    transform: translateY(100%);
  }
  .left,
  .right {
    position: absolute;
    color: #000;
    width: 60px;
    height: 50px;
    line-height: 50px;
    top: 0;
    text-align: center;
  }
  .left {
    left: 0;
    transform: translateX(-100%);
  }
  .right {
    right: 0;
    transform: translateX(100%);
  }
}
</style>
